import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, useParams, Switch } from 'react-router-dom'; 
import { useState, useEffect } from 'react';

import Home from "./pages/Home";
import About from "./pages/About";
import Colleges from "./pages/Colleges";
import CollegeDetails from './pages/CollegeDetails';
import ContactUs from "./pages/ContactUs";
import Blogs from "./pages/Blogs";
import BlogDetails from "./pages/BlogDetails";
import NotificationDetails from "./pages/NotificationDetails";
import ProgramDetails from './pages/ProgramDetails';
import Syllabus from './pages/Syllabus';
import Test from './pages/Test';
import EntranceExam from './pages/EntranceExams';
import PageNotFound from './pages/PageNotFound';

import Navbar from "./Navbar";
import Footer from "./Footer";
import ScrollToTop from "./ScrollTop";


function MyApp() {

    return (
        <>
        <ScrollToTop></ScrollToTop>
        <Navbar />
        <Routes >
                <Route path="*" element={<PageNotFound />}/>
                <Route path="/" element={<Home /> } />
                <Route path="/#notifications" element={<Home /> } />
                <Route path="/about-us" element={<About /> } />
                <Route path="/colleges" element={<Colleges /> } />
                <Route path="/colleges/:id" element={<CollegeDetails /> } />
                <Route path="/blogs" element={<Blogs /> } />
                <Route path="/blogs/:id" element={<BlogDetails /> } />
                <Route path="/notifications/:id" element={<NotificationDetails /> } />
                <Route path="/programs/:id" element={<ProgramDetails /> } />
                <Route path="/contact-us" element={<ContactUs /> } />
                <Route path="/syllabus" element={<Syllabus /> } />
                <Route path="/test" element={<Test /> } />
                <Route path="/entrance-exams" element={<EntranceExam /> } />
        </Routes>
        <Footer/>
        </>
    );
}

if (document.getElementById('page')) {
    ReactDOM.render(
        <BrowserRouter>
            <MyApp />
        </BrowserRouter>
            , document.getElementById('page'));
}
// if (document.getElementById('app')) {
//     ReactDOM.render(<MyApp />, document.getElementById('app'));
// }