import React from "react";
import {Link} from "react-router-dom";
import Swal from 'sweetalert2';
import axios from 'axios'

function Footer() {
    
    return (
        <footer id="colophon" className="site-footer">
            <div className="footer_inner_wrapper footer tm-bg tm-bgimage-yes">
                <div className="site-footer-bg-layer tm-bg-layer"></div>
                <div className="site-footer-w">
                    <div className="footer-rows">
                        <div className="footer-rows-inner">
                        <div id="second-footer" className="sidebar-container second-footer tm-bg tm-textcolor-white tm-bgimage-no" role="complementary">
                            <div className="second-footer-bg-layer tm-bg-layer"></div>
                            <div className="container tm-container-for-footer">
                                <div className="second-footer-inner">
                                    <div className="row multi-columns-row">
                                    <div className="widget-area col-xs-12 col-sm-6 col-md-5 col-lg-5">
                                        <aside id="enhancedtextwidget-2" className="widget-odd widget-3 widget widget_text enhanced-text-widget">
                                            <div className="textwidget widget-text">
                                                <div className="footer_logo">
                                                <h3 className="widget-title">BHMS COLLEGES IN BANGALORE</h3>
                                                </div>
                                                <p>BHMS Colleges in Bangalore was established in the year 2014 with the main objective of assisting the BHMS aspirants to choose the best and ideal BHMS college and finalise an appropriate specialisation that would ensure personal and professional satisfaction.</p>
                                            </div>
                                        </aside>
                                    </div>
                                    <div className="widget-area col-xs-12 col-sm-6 col-md-3 col-lg-3">
                                        <aside id="nav_menu-2" className="widget-even widget-2 widget widget_nav_menu">
                                            <h3 className="widget-title">LINKS</h3>
                                            <div className="menu-footer-links-container">
                                                <ul id="menu-footer-links-1" className="menu">
                                                <li className="menu-item"><Link to="/">Home</Link></li>
                                                <li className="menu-item"><Link to="/about-us">About us</Link></li>
                                                <li className="menu-item"><Link to="/colleges">BHMS Colleges</Link></li>
                                                <li className="menu-item"><a href="/entrance-exams">Entrance Exams</a></li>
                                                <li className="menu-item"><Link to="/syllabus">BHMS Syllabus</Link></li>
                                                <li className="menu-item"><Link to="/blogs">Blogs</Link></li>
                                                <li className="menu-item"><Link to="/contact-us">Contact us</Link></li>
                                                </ul>
                                            </div>
                                        </aside>
                                    </div>
                                    <div className="widget-area col-xs-12 col-sm-6 col-md-4 col-lg-4">
                                        <aside id="themetechmount-recent-posts-3" className="widget-even widget-2 widget themetechmount_widget_recent_entries">
                                            <h3 className="widget-title">CONTACT US</h3>
                                            <p>No. 309, 3rd Floor, Brigade Gardens,
                                                Church Street, MG Road, Bangalore,
                                                Karnataka, India - 560001
                                            </p>
                                            <p><a href="tel:+918095797575">+918095797575</a></p>
                                            <p><a href="mailto:info@bhmscollegesinbangalore.com">info@bhmscollegesinbangalore.com</a></p>
                                            <h3 className="widget-title widjet-title-followus">FOLLOW US</h3>
                                            <ul className="social-icons">
                                                <li className="tm-social-facebook"><a className="social-link" target="_blank" href="#"><i className="fa fa-facebook"></i></a></li>
                                                <li className="tm-social-twitter"><a className="social-link" target="_blank" href="#"><i className="fa fa-twitter"></i></a></li>
                                                <li className="tm-social-instagram"><a className="social-link" target="_blank" href="https://instagram.com/studyinbangalorecolleges"><i className="fa fa-instagram"></i></a></li>
                                                <li className="tm-social-youtube"><a className="social-link" target="_blank" href="https://youtube.com/channel/UChVSKwSYdV_Q48YM8lTmwdw"><i className="fa fa-youtube-play"></i></a></li>
                                            </ul>
                                        </aside>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div id="bottom-footer-text" className="bottom-footer-text tm-bottom-footer-text site-info  tm-bg tm-bgcolor-transparent tm-textcolor-white tm-bgimage-no">
                        <div className="bottom-footer-bg-layer tm-bg-layer"></div>
                        <div className="container tm-container-for-footer">
                        <div className="bottom-footer-inner">
                            <div className="row multi-columns-row">
                                <div className="col-xs-12 col-sm-12 tm-footer2-left ">
                                    <div className="text-center">
                                    Copyright © 2024<script>document.write(new Date().getFullYear())</script> - bhmscollegesinbangalore.com
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            

        </footer>

        
    );
};

export default Footer;