import React from "react";
import {Link} from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useState, useEffect, Component } from 'react';
import Helmet from 'react-helmet';

export default class About extends Component {
    
    constructor(){
        super();
        this.state={
          notifications:[],
          reviews:[]
        }
      }
     
      async componentDidMount() {
        this.getList();
      }
      //API CALLS
      getList=()=>{
        this.getUser();
      }

      notificationDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return month+ ' ' +date+', ' +year;
      }

      async getUser(){
        let usersData=await axios.get('/about-page-data')
                      .then(res=>{
                          this.setState({
                            notifications:res.data.notifications,
                            reviews:res.data.collegerates
                          },()=>{
                            // console.log(this.state)
                          })
                      })
                      .catch(err=>{
                          console.log(err);
                      })
      }

    render(){
        const {notifications}=this.state;
        const {reviews}=this.state;
        return (
          <div id="content-wrapper" className="site-content-wrapper">
            <Helmet>
                <title>About BHMS Colleges in Bangalore | Top Leading Educational Consultants in Bangalore</title>
                <meta name="description" content="About BHMS Colleges in Bangalore - We are one of the Top leading educational partners having services all over the World for academic & career guidance."/>
                <meta name="keywords" content="Top leading educational advisory centre in Bangalore, career guidance centre, educational counsellors in Bangalore, direct admission services, management seats"/>
            </Helmet>
              <div id="content" className="site-content container">
                <div id="content-inner" className="site-content-inner row multi-columns-row">
                  <div id="primary" className="content-area col-md-8 col-lg-8 col-xs-12 sub">
                  <main id="main" className="site-main">
								<div className="row">
									<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
										<div className="vc_column-inner tm-responsive-custom-12751771">
											<div className="wpb_wrapper">
												<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
													<section className="tm-vc_cta3-container tm-sepcolor-default">
														<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
															<div className="tm-vc_cta3_content-container">
																<div className="tm-vc_cta3-content">
																	<header className="tm-vc_cta3-content-header tm-wrap">
																		<div className="tm-vc_cta3-headers tm-wrap-cell">
																			<h3 className="tm-custom-heading ">About BHMS Colleges in Bangalore</h3> </div>
																		<div className="heading-seperator"><span></span></div>
																	</header>
																	<div className="tm-cta3-content-wrapper">
																		<p>BHMS Colleges in Bangalore was established in the year 2014 with the main objective of assisting the BHMS aspirants to choose the best and ideal BHMS college and finalise an appropriate specialisation that would ensure personal and professional satisfaction. Since 2020 we have assisted and guided many candidates in selecting the best homeopathic college in Bangalore, choosing specialisation to build the best careers and to strive towards a prosperous and successful career and life. We have been instrumental in directly connecting the right candidates to the top homeopathic colleges in Bangalore, where they have the freedom to choose the homeopathic course with confidence.</p>
																		<p><b>Exclusive Services offered by BHMS Study in Bangalore</b></p>
																	</div>
																</div>
															</div>
														</div>
													</section>
												</div>
												<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
													<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
														<div className="vc_column-inner  ">
															<div className="wpb_wrapper">
																<ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Exclusive and updated information about Homeopathic Colleges in Bangalore</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Genuine Rankings and ratings of BHMS Colleges</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Latest and updated notifications of BHMS Colleges in Bangalore</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Admission Procedures of different Homeopathic Institutes</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Notifications about NEET-UG entrance exam dates</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Notifications about Government and Private Scholarship Exams</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Genuine reviews about college from alumni students and existing students</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Comparison of different Homeopathic colleges and institutions</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Providing the verified List of Reputed Colleges from our Research Team</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Providing Information about fake Colleges and Universities</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Recommendation of new and trending Specialisations based on job demand</span></li>
																</ul>
															</div>
														</div>
													</div>
												</div>
												<br/>
												<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
													<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
														<div className="vc_column-inner  ">
															<div className="wpb_wrapper">
																<p>The BHMS course equips the students with an in-depth knowledge of the field of homeopathic science and helps them become best and skilled homeopathic doctors. The students are trained in the different sciences and treatments of Homeopathy, so that they gain proper knowledge and develop the ability to apply them into practical use.</p>
																<p>The BHMS course trains the students on various aspects related to homeopathic medicine and treatments such as:</p>
																<ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Introduction to Herbology</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Fundamentals of Ayurvedic plant healing</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Healing through Nutritional Therapies</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Essential Principles of Homeopathy</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Mind and body Healing Techniques</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Introduction to Alternative Medicine</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">History of holistic health</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Immunology and immune system disorders</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Introduction to traditional Chinese medicine</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Health, wellness and longevity</span></li>
																</ul>
																<br/>
																<p>The BHMS course is designed to provide students with a wide range of homeopathic treatments and pharmacy, while building competence to treat the patients using homeopathy. The BHMS course gives an in-depth understanding of the Homeopathic System and the methods of treatments using different medicinal plants available in nature.</p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

						 </main>
                  </div>
                  <aside id="sidebar-right" className="widget-area col-md-4 col-lg-4 col-xs-12 sidebar">
                  <aside id="themetechmount-recent-posts-5" className="widget-odd widget-9 widget themetechmount_widget_recent_entries">
                    <h3 className="widget-title">Notifications</h3>
                    <ul className="p-10 tm-recent-post-list">
                      {notifications && notifications.length > 0 && notifications.map((notification, index) => {
                          return (
                            <li className="tm-recent-post-list-li" key={`n${notification.id}`}>
                                <Link to={`/notifications/${notification.slug}`}>
                                <img width="150" height="150" src={`/storage/notifications/${notification.image2}`} className="attachment-thumbnail size-thumbnail wp-post-image lozad" alt={notification.title} data-loaded="true"/></Link>
                                <Link to={`/notifications/${notification.slug}`}>{notification.title}</Link>
                                <span className="post-date">{this.notificationDate(new Date(notification.updated_at))}</span>
                            </li>
                          );
                      })}
                    </ul>
                  </aside>
                  <aside id="categories-5" className="rating widget-even widget-10 widget widget_categories">
                    <h3 className="widget-title" style={{backgroundColor:"#94af1d"}}>Reviews & Ratings of BHMS Colleges in Bangalore</h3>
                    <div className="rate-box">
                    <div className="recent-box text-left">
                    {reviews && reviews.length > 0 && reviews.map((reviews, index) => {
                      const oneStar = (reviews.rating >= 1) ?  'fa fa-star' :  'fa fa-star-o' ;
                      const twoStar = (reviews.rating >= 2) ?  'fa fa-star' :  'fa fa-star-o' ;
                      const threeStar = (reviews.rating >= 3) ?  'fa fa-star' :  'fa fa-star-o' ;
                      const fourStar = (reviews.rating >= 4) ?  'fa fa-star' :  'fa fa-star-o' ;
                      const fiveSar = (reviews.rating >= 5) ?  'fa fa-star' :  'fa fa-star-o' ;
                      return (
                        <div className="recent" key={`r${reviews.id}`}>
                            <h6>
                              <span>
                                  <div className="rating-stars">
                                    <ul id="">
                                        <li className="star rate-this about-rating-stars" title="1 star" data-value="1">
                                          <i className={oneStar}></i>
                                        </li>
                                        <li className="star rate-this about-rating-stars" title="2 stars" data-value="2">
                                          <i className={twoStar}></i>
                                        </li>
                                        <li className="star rate-this about-rating-stars" title="3 stars" data-value="3">
                                          <i className={threeStar}></i>
                                        </li>
                                        <li className="star rate-this about-rating-stars" title="4 stars" data-value="4">
                                          <i className={fourStar}></i>
                                        </li>
                                        <li className="star rate-this about-rating-stars" title="5 stars" data-value="5">
                                          <i className={fiveSar}></i>
                                        </li>
                                    </ul>
                                  </div>
                              </span>
                              <span>|</span> {reviews.name} <span>|</span> <Link to={`/colleges/${reviews.url}`}>{reviews.college}</Link> <span>|</span> <span>{reviews.date}</span>
                            </h6>
                            <p>{reviews.review}</p>
                        </div>
                      );
                    })}
                    </div>
                    </div>
                  </aside>
                  <aside id="themetechmount-recent-posts-5" className="widget-odd widget-9 widget themetechmount_widget_recent_entries">
                    <h3 className="widget-title about-similar-trending-courses" style={{backgroundColor:"#94af1d"}}>Similar trending courses</h3>
                    <ul className="p-10 similar-crs tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
                      <li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">BNYS</span></li>
                      <li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">BAMS</span></li>
                    </ul>
                  </aside>
                  
                  <aside id="acadevo_contact_widget-4" className="widget-even widget-14 widget acadevo_contact_widget">
                    <a className="hover" href="https://bamscollegesinbangalore.com/" target="_blank"><h3 className="widget-title">Click to know about BAMS Colleges in Bangalore <i className="fa fa-arrow-circle-right" aria-hidden="true"></i></h3></a>
                  </aside>
                  </aside>
                </div>
              </div>
        </div>
        );
    }
}

// export default Home;