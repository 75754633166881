import React from "react";
import {Link} from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useState, useEffect, Component } from 'react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules'; 
import { Swiper, SwiperSlide } from 'swiper/react';
import Helmet from 'react-helmet';
import { Audio } from 'react-loader-spinner'
import Select from 'react-select'
import countryFile from './Country';
import checkNumber from './CheckMobileNumber';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';



export default class Home extends Component {
    
    constructor(){
        super();
        this.state={
          notifications:[],
          blogs:[],
          courses:[],
          countries: [], 
          states: [],
          stateValue:'',
          mobile:''
        }
      }
      
      selectCountry = (val) => {
        this.setState({ stateValue: '' });
        const state_arr = countryFile.s_a[val.key].split("|"); 
        const stateArray = [];
        stateArray.push({
          value: '', 
          label: 'Select'
        });
        for (var i = 0; i < state_arr.length; i++) {
          stateArray.push({
          value: state_arr[i], 
          label: state_arr[i]
          });
        }
        this.setState({ states: stateArray });
    
        var Country = val.value;
        let check = checkNumber.checkNumber(Country,this.state.mobile);
        if(check === 2){
          this.setState({mobile:''});
        }
      }
      
        selectRegion = (val) => {
          if(val.value !== '' && val.value !== null){
            this.setState({stateValue:val});
          } else {
            this.setState({stateValue:''});
          }
        }
        updateMobile = (value) => {
          this.setState({mobile:value});
        }
     
      async componentDidMount() {
        const countryArray = [];
        var country1 = countryFile.country_arr;
        countryArray.push({
          value: '', 
          label: 'Select',
          key:[0]
        });
        for (var i = 0; i < country1.length; i++) {
          countryArray.push({
            value: country1[i], 
            label: country1[i],
            key:[i+1]
          });
        }
        this.setState({countries:countryArray});
        this.getList();
      }
      //API CALLS
      getList=()=>{
        this.getUser();
      }

      notificationDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return month+ ' ' +date+', ' +year;
      }

      async getUser(){
        let usersData=await axios.get('/home-page-data')
            .then(res=>{
                this.setState({
                    notifications:res.data.notifications,
                    blogs:res.data.blogs,
                    courses:res.data.courses,
                    colleges:res.data.colleges
                },()=>{
                    // console.log(this.state)
                })
            })
            .catch(err=>{
                console.log(err);
            })
      }

      resetForm(){
        document.getElementById('home-page-middle-form').reset();
		// this.setState({mobile:''});
		jQuery('.EnquiryMobile').val('');
      }

	  truncate (str) {
		return str.length > 40 ? str.substring(0, 30) + ".." : str;
	  }

      handleSubmit(event) {
        event.preventDefault();
        jQuery("#overlay").fadeIn(300);　
        const formData = new FormData(event.target);
        axios.post('/store-enquiry', formData)
        .then(({data})=>{
            event.target.reset()
            jQuery('#enquiry').modal('hide');
            if(data.status === 200){
                Swal.fire({
                    icon:"success",
                    text:data.message
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else if(data.status === 201){
                Swal.fire({
                    text:'We have already received an enquiry from this mobile number or email.',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else {
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            }
        })
		
      }

    render(){
        const {notifications}=this.state;
        const {blogs}=this.state;
        const {courses}=this.state;
        const {colleges}=this.state;
        const myStyle = {
          textAlign:"left",
          fontSize:"24px",
          lineHeight:"37px",
          fontFamily:"'Poppins'",
          fontWeight:"400",
          color:"#fff !important" 
        }
        const { countries, states, stateValue , mobile} = this.state;
        return (
          <div id="content-wrapper" className="site-content-wrapper">
            <Helmet>
                    <title>Top BHMS Colleges in Bangalore | Get Direct admission for BHMS colleges | Admissions Open 2023-24</title>
                    <meta name="description" content="Get direct admissions to Top BHMS Colleges in Bangalore without any hassle. We provide support services from counselling to admissions enrolment at top colleges. Call now!"/>
                    <meta name="keywords" content="best bca colleges in Bangalore, top bca colleges in Bangalore, direct admissions for bca course, admissions at bca colleges in Bangalore, get direct admissions in top colleges in bangalore."/>  
            </Helmet>
            <div id="content" className="site-content ">
             <div id="content-inner" className="site-content-inner ">
                <div id="primary" className="content-area">
                  <main id="main" className="site-main">
                      <article id="post-2133" className="post-2133 page type-page status-publish hentry">
                         <header className="single-entry-header tm-hide">
                            <h2 className="entry-title">BHMS Colleges</h2>
                         </header>
                         <div className="entry-content">
                            <div className="vc_row-full-width vc_clearfix"></div>
                            <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom hidden-sm hidden-md hidden-lg visible-xs">
                                <div className="vc_row container">
                                  <div className="wpb_column tm-column vc_column_container vc_col-sm-5 tm-zindex-0 margin-15px-991-colum">
                                      <div className="vc_column-inner  ">
                                        <div className="wpb_wrapper">
                                            <div className="res-slider-form">
                                              <div className="widget">
                                                  <h4 className="widget-title text-center">Quick Enquiry</h4>
                                                  <p className="text-center">Apply now to get admission for BHMS</p>
                                                  <div className="widget-content">
                                                    <form onSubmit={this.handleSubmit}>
                                                        <div className="row">
                                                          <div className="col-xs-12">
                                                              <div className="text-block">
                                                                <input type="text" name="name" placeholder="Name *" required/> 
                                                              </div>
                                                          </div>
                                                          <div className="col-xs-12">
                                                              <div className="text-block">
                                                                <input type="email" name="email" placeholder="Email *" required/> 
                                                              </div>
                                                          </div>
                                                          <div className="col-xs-12">
                                                              <div className="text-block">
                                                                <input type="text" value={mobile} onChange={e => this.updateMobile(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}  className="EnquiryMobile" name="phone" placeholder="Phone *" required/> 
                                                              </div>
                                                          </div>
                                                          <div className="col-xs-12">
                                                              <div className="text-block">
                                                                <input type="text" name="course" placeholder="Course *" required/>
                                                                <input type="hidden" name="source" value="2"/> 
                                                              </div>
                                                          </div>
                                                          <div className="col-xs-12 pad-5">
                                                            <div className="text-block">
                                                                <Select options={countries} name="country"  placeholder="Select Country" required onChange={this.selectCountry} />
                                                            </div>
                                                          </div>
                                                          <div className="col-xs-12 pad-5">
                                                            <div className="text-block">
                                                                <Select options={states} value={stateValue} name="state" className="select-state" placeholder="Select State" required onChange={this.selectRegion} />
                                                            </div>
                                                          </div>
                                                          <div className="col-xs-12 text-center">
                                                              <input type="submit" value="Submit" className="btn submit"/> 
                                                          </div>
                                                        </div>
                                                    </form>
                                                  </div>
                                              </div>
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                            </div>

                            <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_bz2 tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom">
											<div className="vc_row container">
												<div className="wpb_column tm-column vc_column_container vc_col-sm-5 tm-zindex-0 margin-15px-991-colum">
													<div className="vc_column-inner  ">
														<div className="wpb_wrapper">
															<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid tm-responsive-custom-10018428 tm-zindex-0">
																<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 vc_col-has-fill tm-zindex-0">
																	<div className="vc_column-inner vc_custom_1567594802767 ">
																		<div className="wpb_wrapper">
																			<div className="wpb_single_image wpb_content_element vc_align_left  vc_custom_1567595065320">
																				<figure className="wpb_wrapper vc_figure">
																					<div className="vc_single_image-wrapper vc_box_border_grey"><img src="/assets/img/about.jpg" className="vc_single_image-img attachment-full lozad" alt="What is BHMS?" /></div>
																				</figure>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="wpb_column tm-column vc_column_container vc_col-sm-7 tm-zindex-0 margin-15px-991-colum">
													<div className="vc_column-inner tm-responsive-custom-12751771">
														<div className="wpb_wrapper">
															<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
																<section className="tm-vc_cta3-container tm-sepcolor-default">
																	<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
																		<div className="tm-vc_cta3_content-container">
																			<div className="tm-vc_cta3-content">
																				<header className="tm-vc_cta3-content-header tm-wrap">
																					<div className="tm-vc_cta3-headers tm-wrap-cell">
																						<h4 className="tm-custom-heading">What is</h4>
																						<h3 className="tm-custom-heading ">BHMS?</h3> </div>
																					<div className="heading-seperator"><span></span></div>
																				</header>
																				<div className="tm-cta3-content-wrapper">
																					<p>Bachelor of Homeopathic Medicine & Surgery (BHMS) is an undergraduate programme that covers in-depth medical knowledge of the homeopathic system. The course is of 5.5-years duration. The main aim of the course is to cover the theoretical and practical knowledge of the homeopathic system. Homeopathy is based on the self-healing power of our bodies and focuses on accounting that.</p>
																				</div>
																			</div>
																		</div>
																	</div>
																</section>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

                    <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684117259 tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom">
											<div className="vc_row container">
												<div className="wpb_column tm-column vc_column_container vc_col-sm-5 tm-zindex-0 margin-15px-991-colum hidden-lg hidden-md hidden-sm visible-xs">
													<div className="vc_column-inner  ">
														<div className="wpb_wrapper">
															<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid tm-responsive-custom-10018428 tm-zindex-0">
																<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 vc_col-has-fill tm-zindex-0">
																	<div className="vc_column-inner vc_custom_1567594802767 ">
																		<div className="wpb_wrapper">
																			<div className="wpb_single_image wpb_content_element vc_align_left  vc_custom_1567595065320">
																				<figure className="wpb_wrapper vc_figure">
																					<div className="vc_single_image-wrapper vc_box_border_grey"><img src="/assets/img/about-two.jpg" className="vc_single_image-img attachment-full lozad" alt="What is Homeopathy?" /></div>
																				</figure>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="wpb_column tm-column vc_column_container vc_col-sm-7 tm-zindex-0 margin-15px-991-colum">
													<div className="vc_column-inner tm-responsive-custom-12751771">
														<div className="wpb_wrapper">
															<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
																<section className="tm-vc_cta3-container tm-sepcolor-default">
																	<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
																		<div className="tm-vc_cta3_content-container">
																			<div className="tm-vc_cta3-content">
																				<header className="tm-vc_cta3-content-header tm-wrap">
																					<div className="tm-vc_cta3-headers tm-wrap-cell">
																						<h4 className="tm-custom-heading">What is</h4>
																						<h3 className="tm-custom-heading ">Homeopathy?</h3> </div>
																					<div className="heading-seperator"><span></span></div>
																				</header>
																				<div className="tm-cta3-content-wrapper">
																					<p>Homeopathy is a holistic medicine that uses specifically prepared, highly diluted substances with the aim of triggering the body’s own healing mechanism. Homeopathy is based on the principle of “like treats like” i.e. a substance which can cause symptoms when taken in large doses can be used in small amounts as medicine to treat similar symptoms. The concept of homeopathy is sometimes used in conventional medicine to treat various health conditions.</p>
																					<p>Homeopathy is treatment based on the use of highly diluted substances which practitioners claim can cause the body to heal itself. Homeopathy is basically a complementary or alternative medicine different from treatments that are part of conventional western medicine in important ways.</p>
																				</div>
																			</div>
																		</div>
																	</div>
																</section>
															</div>
														</div>
													</div>
												</div>
												<div className="wpb_column tm-column vc_column_container vc_col-sm-5 tm-zindex-0 margin-15px-991-colum hidden-xs visible-sm visible-md visible-lg">
													<div className="vc_column-inner">
														<div className="wpb_wrapper">
															<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid tm-responsive-custom-10018428 tm-zindex-0">
																<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 vc_col-has-fill tm-zindex-0">
																	<div className="vc_column-inner vc_custom_1567594802767 ">
																		<div className="wpb_wrapper">
																			<div className="wpb_single_image wpb_content_element vc_align_left  vc_custom_1567595065320">
																				<figure className="wpb_wrapper vc_figure">
																					<div className="vc_single_image-wrapper vc_box_border_grey"><img src="/assets/img/about-two.jpg" className="vc_single_image-img attachment-full lozad" alt="What is Homeopathy?" /></div>
																				</figure>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div className="vc_row container">
												<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
													<div className="vc_column-inner tm-responsive-custom-12751771">
														<div className="wpb_wrapper">  
															<hr/>
															<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
																<section className="tm-vc_cta3-container tm-sepcolor-default">
																	<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
																		<div className="tm-vc_cta3_content-container">
																			<div className="tm-vc_cta3-content">
																				<header className=" tm-wrap">
																					<div className="tm-vc_cta3-headers tm-wrap-cell">
																						<h5 className="tm-custom-heading" style={{marginTop:"20px"}}>Duration</h5>
																						<p>The Bachelor of Homeopathy Medicine and Surgery (BHMS) is a 5.5-years graduate degree course. The students will study 4.5 years of BHMS degree and will also undergo one-year mandatory internship.</p>
																					</div>
																				</header>
																			</div>
																		</div>
																	</div>
																</section>
															</div>
														</div>
													</div>
												</div>
												<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
													<div className="vc_column-inner tm-responsive-custom-12751771">
														<div className="wpb_wrapper">
															<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
																<section className="tm-vc_cta3-container tm-sepcolor-default">
																	<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
																		<div className="tm-vc_cta3_content-container">
																			<div className="tm-vc_cta3-content">
																				<header className=" tm-wrap">
																					<div className="tm-vc_cta3-headers tm-wrap-cell">
																						<h5 className="tm-custom-heading" style={{marginTop:"20px"}}>Eligibility</h5> </div>
																				</header>
																			</div>
																		</div>
																	</div>
																</section>
															</div>
															<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
																<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
																	<div className="vc_column-inner  ">
																		<div className="wpb_wrapper">
																			<p>The students seeking admission to BHMS should have passed their PUC or 10+2 classes with PCB or CBZ with 50% aggregate from the recognised board. The eligible students should apply and qualify the NEET entrance exam to get admission to the BHMS program.</p>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
                              
                    <div className="vc_row-full-width vc_clearfix"></div>
										<div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684117259 tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom" style={{backgroundColor:"#f0f0f0"}}>
											<div className="vc_row container">
												<div className="wpb_column tm-column vc_column_container vc_col-sm-6 tm-zindex-0 margin-15px-991-colum">
													<div className="vc_column-inner tm-responsive-custom-12751771">
														<div className="wpb_wrapper">
															<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
																<section className="tm-vc_cta3-container tm-sepcolor-default">
																	<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
																		<div className="tm-vc_cta3_content-container">
																			<div className="tm-vc_cta3-content">
																				<header className="tm-vc_cta3-content-header tm-wrap">
																					<div className="tm-vc_cta3-headers tm-wrap-cell">
																						<h5 className="tm-custom-heading ">Affiliations and Approval</h5> </div>
																					<div className="heading-seperator"><span></span></div>
																				</header>
																			</div>
																		</div>
																	</div>
																</section>
															</div>
															<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
																<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
																	<div className="vc_column-inner  ">
																		<div className="wpb_wrapper">
																			
																			<ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
																				<p>The BHMS course is offered at both Government University and Private Universities recognised under Central Council of Indian Medicine, Ministry of AYUSH, Government of India. Before going for details, it is always essential to understand the difference between Private University (Under Central Council of Indian Medicine, Ministry of Ayush, Government of India) and Rajiv Gandhi University of Health Sciences (Government university) in Bangalore.</p>
																				<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Central Council of Indian Medicine, Ministry of AYUSH</b></span></li>
																				<p>The Central Council of Indian Medicine (CCIM), under Ministry of AYUSH, Government of India is the sole governing body for all the courses of Ayurveda, Unani, Siddha, Homeopathy (AYUSH) offered in the country. All the colleges offering AYUSH courses must be recognised by the Central Council of Indian Medicine (CCIM), Government of India and also the respective state governing bodies of the state (RGUHS in case of Karnataka).</p>
																				<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Rajiv Gandhi University of Health Sciences (RGUHS)</b></span></li>
																				<p>Rajiv Gandhi University of Health Sciences (RGUHS) is the sole university that is established or financed by the government of India and state governments to offer different courses in the country. The government universities are established or incorporated by or under a Central Act, a Provincial Act or a State Act. The Rajiv Gandhi University of Health Sciences (RGUHS) is duly recognised by Indian Medical Council (IMC) by default. The Rajiv Gandhi University of Health Sciences (RGUHS) is the sole governing body under Government of India to offer courses in Medical Science, Allied Health Science courses across the country.</p>
																				
																			</ul>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="wpb_column tm-column vc_column_container vc_col-sm-5 vc_col-sm-offset-1 tm-zindex-0 margin-15px-991-colum" id="notifications">
													<div className="vc_column-inner tm-responsive-custom-12751771">
														<div className="wpb_wrapper">
															<hr className="hidden-sm visible-xs"/>
															<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
																<section className="tm-vc_cta3-container tm-sepcolor-default">
																	<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
																		<div className="tm-vc_cta3_content-container">
																			<div className="tm-vc_cta3-content">
																				<header className="tm-vc_cta3-content-header tm-wrap">
																					<div className="tm-vc_cta3-headers tm-wrap-cell">
																						<h5 className="tm-custom-heading "><span className="bell fa fa-bell"></span> Notifications</h5> </div>
																					<div className="heading-seperator"><span></span></div>
																				</header>
																			</div>
																		</div>
																	</div>
																</section>
															</div>
															<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
																<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
																	<div className="vc_column-inner  ">
																		<div className="wpb_wrapper">
                                 											   <ul className="m-0 tm-recent-post-list scrollable-list">
                                 											    {notifications && notifications.length > 0 && notifications.map((notification, index) => {
                                 											      return (
                                 											      <li className="tm-recent-post-list-li" key={`n${notification.id}`}>
                                 											        <Link to={`/notifications/${notification.slug}`}>
                                 											          <img width="150" height="150" src={`/storage/notifications/${notification.image2}`} className="attachment-thumbnail size-thumbnail wp-post-image lozad" alt={notification.title} data-loaded="true"/></Link>
                                 											            <Link to={`/notifications/${notification.slug}`}>{notification.title}</Link>
                                 											            <span className="post-date">{this.notificationDate(new Date(notification.updated_at))}</span>
                                 											      </li>
                                 											      );
                                 											    })}
                                 											  </ul>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
                            <div className="vc_row-full-width vc_clearfix"></div>
                              <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684293976 tm-responsive-custom-10696870 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-left_center tm-bg tm-bgimage-yes">
                                <div className="tm-row-wrapper-bg-layer tm-bg-layer"></div>
                                <div className="vc_row container vc_row-o-equal-height vc_row-flex">
                                  <div className="wpb_column tm-column vc_column_container vc_col-sm-8 col-sm-offset-2 tm-overlap-row tm-span tm-left-span tm-zindex-2 margin-15px-991-colum">
                                      <div className="apply-now">
                                        <div className="widget">
                                            <h4 className="widget-title text-center">Apply now</h4>
                                            <p className="text-center">Apply now to get admission for BHMS</p>
                                            <div className="widget-content">
                                              <form onSubmit={this.handleSubmit} id="home-page-middle-form">
                                                  <div className="row">
                                                    <div className="col-xs-12 col-sm-6">
                                                        <div className="text-block">
                                                          <input type="text" name="name" placeholder="Name *" required/> 
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-6">
                                                        <div className="text-block">
                                                          <input type="email" name="email" placeholder="Email *" required/> 
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-6">
                                                        <div className="text-block">
                                                          <input type="text" name="phone" value={mobile} onChange={e => this.updateMobile(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}  className="EnquiryMobile" placeholder="Phone *" required /> 
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-6">
                                                        <div className="text-block">
                                                          <input type="text" name="course" placeholder="Course *" required/>
                                                          <input type="hidden" name="source" value="2"/> 
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-6">
                                                      <div className="text-block">
                                                        <Select options={countries} name="country"  placeholder="Select Country" required onChange={this.selectCountry} />
                                                      </div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-6">
                                                      <div className="text-block">
                                                        <Select options={states} value={stateValue} name="state" className="select-state" placeholder="Select State" required onChange={this.selectRegion} />
                                                      </div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-6 text-center hidden-xs">
                                                        <input onClick={this.resetForm} type="button" value="Reset" className="btn clear-btn"/> 
                                                    </div>
                                                    <div className="col-xs-12 col-sm-6 text-center">
                                                        <input type="submit" value="Submit" className="btn submit"/> 
                                                    </div>
                                                  </div>
                                              </form>
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                            </div>

                            <div className="vc_row-full-width vc_clearfix"></div>
                              <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684117259 tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom">
                                <div className="vc_row container">
                                  <div className="wpb_column tm-column vc_column_container vc_col-sm-6 tm-zindex-0 margin-15px-991-colum">
                                      <div className="vc_column-inner tm-responsive-custom-12751771">
                                        <div className="wpb_wrapper">
                                            <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                              <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                  <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                    <div className="tm-vc_cta3_content-container">
                                                        <div className="tm-vc_cta3-content">
                                                          <header className="tm-vc_cta3-content-header tm-wrap">
                                                              <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                                <h5 className="tm-custom-heading ">Blogs</h5>
                                                              </div>
                                                              <div className="heading-seperator"><span></span></div>
                                                          </header>
                                                        </div>
                                                    </div>
                                                  </div>
                                              </section>
                                            </div>
                                            <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                            {blogs && blogs.length > 0 && blogs.map((blogloop, index) => {
                                              return (
                                              <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-6 tm-zindex-0" key={`b${index}`}>
                                                  <div className="vc_column-inner  ">
                                                    <div className="wpb_wrapper">
                                                        <ul className="m-0 tm-recent-post-list">
                                                        {blogloop && blogloop.length > 0 && blogloop.map((blog, index2) => {
                                                          return (
                                                          <li className="tm-recent-post-list-li" key={`bl${blog.id}`}>
                                                              <Link to={`/blogs/${blog.slug}`}><img width="150" height="150" src={`/storage/blogs/${blog.image2}`} className="attachment-thumbnail size-thumbnail wp-post-image" alt={blog.title}/></Link>
                                                              <Link to={`/blogs/${blog.slug}`}>{this.truncate(blog.title)}</Link>
                                                              <span className="post-date">{this.notificationDate(new Date(blog.updated_at))}</span>
                                                          </li>
                                                          )
                                                        })}
                                                        </ul>
                                                    </div>
                                                  </div>
                                              </div>
                                              )
                                            })}
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                                  <div className="wpb_column tm-column vc_column_container vc_col-sm-5 vc_col-sm-offset-1 tm-zindex-0 margin-15px-991-colum">
                                      <div className="vc_column-inner tm-responsive-custom-12751771">
                                        <div className="wpb_wrapper">
                                            <hr className="hidden-sm visible-xs"/>
                                            <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                              <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                  <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                    <div className="tm-vc_cta3_content-container">
                                                        <div className="tm-vc_cta3-content">
                                                          <header className="tm-vc_cta3-content-header tm-wrap">
                                                              <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                                <h5 className="tm-custom-heading ">Similar Trending courses</h5>
                                                              </div>
                                                              <div className="heading-seperator"><span></span></div>
                                                          </header>
                                                        </div>
                                                    </div>
                                                  </div>
                                              </section>
                                            </div>
                                            <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                              <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
                                                  <div className="vc_column-inner  ">
                                                    <div className="wpb_wrapper">
                                                    <ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
                                                    {courses && courses.length > 0 && courses.map((course, index) => {
                                                      return (
                                                        <li key={`cs${course.id}`}>
                                                          <i className="tm-skincolor fa fa-arrow-circle-right"></i>  
                                                          <span className="tm-list-li-content">
                                                            <Link to={course.url} target="_blank">{course.course}</Link>
                                                          </span>
                                                        </li>
                                                      )
                                                    })}
                                                    </ul>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div className="col-sm-10 text-center">
                                                  <hr/>
                                                  <a className="apply-btn" href="#enquiry" data-toggle="modal">Apply now</a> 
                                              </div>
                                            </div>
                                            <hr/>
															          <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
																            <section className="tm-vc_cta3-container tm-sepcolor-default">
																	            <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
																		            <div className="tm-vc_cta3_content-container">
																			            <div className="tm-vc_cta3-content">
																				            <header className="tm-vc_cta3-content-header tm-wrap">
																					              <div className="tm-vc_cta3-headers tm-wrap-cell">
																						              <h5 className="tm-custom-heading">BHMS Entrance Exams</h5> </div>
																					              <div className="heading-seperator"><span></span></div>
																				            </header>
																			            </div>
																		            </div>
																	            </div>
																            </section>
															            </div>
                                          <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
																                <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
																	                  <div className="vc_column-inner  ">
																		                    <div className="wpb_wrapper">
																			                    <ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
																				                  <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b><Link to={"/entrance-exams"}>UG-NEET</Link></b></span></li>
																			                  </ul>
																		                </div>
																	                </div>
																              </div>
															              </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                            </div>
                    <div className="vc_row-full-width vc_clearfix"></div>
										<div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684293975 tm-responsive-custom-10696870 vc_row-has-fill break-991-colum tm-zindex-0 tm-textcolor-white tm-bgimage-position-left_center tm-bg tm-bgimage-yes">
											<div className="tm-row-wrapper-bg-layer tm-bg-layer"></div>
											<div className="vc_row container vc_row-o-equal-height vc_row-flex">
												<div className="wpb_column tm-column vc_column_container vc_col-sm-8 tm-overlap-row tm-span tm-left-span tm-zindex-2 margin-15px-991-colum">
													<div className="vc_column-inner vc_custom_1567664250316 tm-responsive-custom-75919887">
														<div className="wpb_wrapper">
															<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567774236927 tm-responsive-custom-64911954 tm-zindex-0">
																<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid tm-bgcolor-skincolor vc_custom_1567774428913 tm-responsive-custom-71053255 tm-zindex-0" style={{backgroundColor:"#2e6a3e"}}>
																	<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-10 tm-zindex-0">
																		<div className="vc_column-inner vc_custom_1567658690491 ">
																			<div className="wpb_wrapper">
																				<h2 style={myStyle} className="tm-custom-heading vc_custom_1567661288254">Why should one choose Bangalore city to study BHMS?</h2> </div>
																		</div>
																	</div>
																</div>
																<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
																	<div className="vc_column-inner tm-responsive-custom-12751771">
																		<div className="wpb_wrapper eligibility-list">
																			<p style={{marginTop:"20px"}}>The BHMS is the most popular course opted by students after MBBS, BDS in the medical field. There is a huge demand for skilled homeopathic doctors in recent times especially in Bangalore. <br/>Some of the reasons that makes Bangalore a best place for studying BHMS are:</p>
																			<ul className="tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591385700">
																				<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">The Bangalore is turning into a hub for homeopathic hospitals and colleges, so the demand has increased drastically for the skilled homeopathy doctors in the last decade</span></li>
																				<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">The students can make a noble career in homeopathy by providing the best medicine and cure for the patients</span></li>
																				<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">There are good number of homeopathic clinics and hospitals across the state and are looking for skilled homeopathic doctors and are ready to pay handsome salaries</span></li>
																				<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">The BHMS doctors can even set up their own clinics and start practicing to earn an extra sum of money apart from normal salaries</span></li>
																				<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">The government is encouraging homeopathic doctors in more numbers and the skilled doctors can expect excellent salary packages and enormous career growth in the field of Homeopathy</span></li>
																				<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">With more number of people choosing Homeopathic treatments and medicines over allopathy, there is a huge demand for homeopathy doctors</span></li>
																			</ul>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
                            <div className="vc_row-full-width vc_clearfix"></div>
                            <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684117259 tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom">
                                <div className="vc_row container">
                                  <div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
                                      <div className="vc_column-inner tm-responsive-custom-12751771">
                                        <div className="wpb_wrapper">
                                            <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                              <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                  <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                    <div className="tm-vc_cta3_content-container">
                                                        <div className="tm-vc_cta3-content">
                                                          <header className="tm-vc_cta3-content-header tm-wrap">
                                                              <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                                <h5 className="tm-custom-heading">Top BHMS colleges in Bangalore</h5>
                                                              </div>
                                                              <div className="heading-seperator"><span></span></div>
                                                          </header>
                                                        </div>
                                                    </div>
                                                  </div>
                                              </section>
                                            </div>
                                            <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                            {colleges && colleges.length > 0 && colleges.map((collegeloop, index) => {
                                              return (
                                              <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0" key={`clg${index}`}>
                                                  <div className="vc_column-inner  ">
                                                    <div className="wpb_wrapper">
                                                        <ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
                                                        {collegeloop && collegeloop.length > 0 && collegeloop.map((college, index2) => {
                                                          return (
                                                            <li key={`clgi${college.id}`}>
                                                              <i className="tm-skincolor fa fa-arrow-circle-right"></i>
                                                              <span className="tm-list-li-content"><b>
                                                                <Link to={`/colleges/${college.slug}`}>{college.college}</Link></b></span>
                                                            </li>
                                                          )
                                                        })}
                                                        </ul>
                                                    </div>
                                                  </div>
                                              </div>
                                              )
                                            })}      
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                            </div>
                            <div className="vc_row-full-width vc_clearfix"></div>
										<div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684117259 tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom" style={{backgroundColor:"#f0f0f0"}}>
											<div className="vc_row container">
												<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
													<div className="vc_column-inner tm-responsive-custom-12751771">
														<div className="wpb_wrapper">
															<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
																<section className="tm-vc_cta3-container tm-sepcolor-default">
																	<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
																		<div className="tm-vc_cta3_content-container">
																			<div className="tm-vc_cta3-content">
																				<header className="tm-vc_cta3-content-header tm-wrap">
																					<div className="tm-vc_cta3-headers tm-wrap-cell">
																						<h5 className="tm-custom-heading">Career Scope for BHMS</h5> </div>
																					<div className="heading-seperator"><span></span></div>
																				</header>
																			</div>
																		</div>
																	</div>
																</section>
															</div>
															<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
																<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
																	<div className="vc_column-inner  ">
																		<div className="wpb_wrapper">
																			<p>The BHMS doctors have excellent job opportunities in the field of homeopathic medical sciences. The BHMS students can practice as Homeopathic Doctors in hospitals, clinics and communities. They can join Homeopathic hospitals and wellness centres to practice homeopathy medicine. They can join as physicians in homeopathic hospitals, clinics, spas, health and wellness centres or they can even join as lecturers in homeopathic medical colleges.</p>
																			<p>The BHMS students have ample job opportunities. They can join MD/MS (Homeopathy), Master of Public Health (MPH), Master of Hospital Administration (MHA), MBA Hospital and Health Care Management, etc.  They can join as professors in homeopathic medical colleges and start teaching Homeopathy. They can even start their own clinics and start practicing in the communities and neighbourhoods. There is a huge demand for BHMS doctors abroad and many foreign countries are readily hiring skilled BHMS doctors for higher salary packages due to increased demand for homeopathy across the globe.</p>
																			<div className="more-content" style={{display:"none"}}>
																			<p>Today, more and more people are having access to health insurance and more people are opting for homeopathic treatments for long term illnesses because it is very cost effective. It seems that homeopathic hospitals are having time keeping up with the demand for patients due to lack of skilled homeopathic physicians</p>
																			<p>The global surveys claim that by 2025 there will be a projected rate of 20% increase in the demand for homeopathic doctors across the globe. The homeopathic physicians’ positions in hospitals tend to fare well when it comes to earnings. The increased demand for homeopathic doctors in the healthcare field has forced the health organisations to pay handsome salaries for skilled homeopathic doctors. They are not limited to one specific field of work, but they have diverse modes of earning such as working in homeopathic hospitals, homeopathic nursing homes, homeopathic clinics, rehabilitation centres, etc.</p>
																			<p>The homeopathic doctors today can be entrepreneurs, administrators, policymakers, bloggers, professors, researchers and even doctors of nursing practice at uppermost levels of homeopathic doctor positions. There are many other opportunities out in the government and private homeopathic hospitals and homeopathic clinics.</p>
																			</div>
																			<Link to="#" className="btn more more-btn">Read more</Link>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>     
                    <div className="vc_row-full-width vc_clearfix"></div>
                      <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684293977 tm-responsive-custom-10696870 vc_row-has-fill break-991-colum tm-zindex-0 tm-textcolor-white tm-bgimage-position-bottom_center tm-bg tm-bgimage-yes">
											<div className="tm-row-wrapper-bg-layer tm-bg-layer"></div>
											<div className="vc_row container vc_row-o-equal-height vc_row-flex">
												<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-overlap-row tm-span tm-left-span tm-zindex-2 margin-15px-991-colum">
													<div className="vc_column-inner vc_custom_1567664250317 tm-responsive-custom-75919887">
														<div className="wpb_wrapper">
															<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
																<section className="tm-vc_cta3-container tm-sepcolor-default">
																	<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
																		<div className="tm-vc_cta3_content-container">
																			<div className="tm-vc_cta3-content">
																				<header className="tm-vc_cta3-content-header tm-wrap">
																					<div className="tm-vc_cta3-headers tm-wrap-cell">
																						<h5 className="tm-custom-heading ">BHMS Placements and Salary Package</h5> </div>
																					<div className="heading-seperator"><span></span></div>
																				</header>
																			</div>
																		</div>
																	</div>
																</section>
															</div>
															<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
																<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-10 tm-zindex-0">
																	<div className="vc_column-inner  ">
																		<div className="wpb_wrapper study-list">
																			<p>The salary prospects and job opportunities differ from students to students. Basically, the placement opportunities depend on academic performance and skill set that the students possess with them.</p>
																			<p><b>Some of the factors that effect on the placement opportunities for the students are</b></p>
																			<div className="bz-collapse">
																			<div className="panel-group" id="bzCollapse" role="tablist" aria-multiselectable="true">
																				<div className="panel panel-default">
																				  <div className="panel-heading" role="tab" id="headingOne">
																					<h4 className="panel-title">
																					  <a role="button" data-toggle="collapse" data-parent="#bzCollapse" href="#collapseOpenOne" aria-expanded="true" aria-controls="collapseOpenOne">
																						Academic Background, Performance and Record
																					  </a>
																					</h4>
																				  </div>
																				  <div id="collapseOpenOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
																					<div className="panel-body">
																					  <p>An excellent academic record always ensures excellent salary packages.</p>
																					</div>
																				  </div>
																				</div>
																				<div className="panel panel-default">
																				  <div className="panel-heading" role="tab" id="headingTwo">
																					<h4 className="panel-title">
																					  <a className="collapsed" role="button" data-toggle="collapse" data-parent="#bzCollapse" href="#collapseOpenTwo" aria-expanded="false" aria-controls="collapseTwo">
																						Candidate Profile
																					  </a>
																					</h4>
																				  </div>
																				  <div id="collapseOpenTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
																					<div className="panel-body">
																					  <p>A smart, logical and alert candidates have potential to earn better salaries.</p>
																					</div>
																				  </div>
																				</div>
																				<div className="panel panel-default">
																				  <div className="panel-heading" role="tab" id="headingThree">
																					<h4 className="panel-title">
																					  <a className="collapsed" role="button" data-toggle="collapse" data-parent="#bzCollapse" href="#collapseOpenThree" aria-expanded="false" aria-controls="collapseThree">
																						Type of Homeopathic College
																					  </a>
																					</h4>
																				  </div>
																				  <div id="collapseOpenThree" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
																					<div className="panel-body">
																					  <p>The BHMS graduation if pursued in A+ category college always ensures better salary packages.</p>
																					</div>
																				  </div>
																				</div>
																				<div className="panel panel-default">
																					<div className="panel-heading" role="tab" id="headingFour">
																					  <h4 className="panel-title">
																						<a className="collapsed" role="button" data-toggle="collapse" data-parent="#bzCollapse" href="#collapseOpenFour" aria-expanded="false" aria-controls="collapseFour">
																							Additional Certifications
																						</a>
																					  </h4>
																					</div>
																					<div id="collapseOpenFour" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
																					  <div className="panel-body">
																						<p>Additional Certifications related to latest subjects ensures that merit-practical exposure and skill enhancement that guarantees a high salary.</p>
																					  </div>
																					</div>
																				</div>
																				<div className="panel panel-default">
																					<div className="panel-heading" role="tab" id="headingFive">
																					  <h4 className="panel-title">
																						<a className="collapsed" role="button" data-toggle="collapse" data-parent="#bzCollapse" href="#collapseOpenFive" aria-expanded="false" aria-controls="collapseFive">
																							Government & Private-level job selection examinations and personal interviews
																						</a>
																					  </h4>
																					</div>
																					<div id="collapseOpenFive" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive">
																					  <div className="panel-body">
																						<p>This proves that the candidates with high-performance records are eligible for high salary packages.</p>
																					  </div>
																					</div>
																				</div>
																				<div className="panel panel-default">
																					<div className="panel-heading" role="tab" id="headingSix">
																					  <h4 className="panel-title">
																						<a className="collapsed" role="button" data-toggle="collapse" data-parent="#bzCollapse" href="#collapseOpenSix" aria-expanded="false" aria-controls="collapseSix">
																							Specialisations
																						</a>
																					  </h4>
																					</div>
																					<div id="collapseOpenSix" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSix">
																					  <div className="panel-body">
																						<p>The colleges today offer different specialisations for different BHMS courses. The salaries also vary according to the specialisations in the industry.</p>
																					  </div>
																					</div>
																				</div>
																				<div className="panel panel-default">
																					<div className="panel-heading" role="tab" id="headingSeven">
																					  <h4 className="panel-title">
																						<a className="collapsed" role="button" data-toggle="collapse" data-parent="#bzCollapse" href="#collapseOpenSeven" aria-expanded="false" aria-controls="collapseSeven">
																							Type of Organisation
																						</a>
																					  </h4>
																					</div>
																					<div id="collapseOpenSeven" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSeven">
																					  <div className="panel-body">
																						<p>The salaries also depend upon type of organisations, top organisations and standard and reputed organisations with name and fame will offer good salary packages when compared to small organisations.</p>
																					  </div>
																					</div>
																				</div>
																				<div className="panel panel-default">
																					<div className="panel-heading" role="tab" id="headingEight">
																					  <h4 className="panel-title">
																						<a className="collapsed" role="button" data-toggle="collapse" data-parent="#bzCollapse" href="#collapseOpenEight" aria-expanded="false" aria-controls="collapseEight">
																							Skills
																						</a>
																					  </h4>
																					</div>
																					<div id="collapseOpenEight" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingEight">
																					  <div className="panel-body">
																						<p>The students with relevant homeopathic skills also guarantee a very good salary for the BHMS doctors.</p>
																					  </div>
																					</div>
																				</div>
																				<div className="panel panel-default">
																					<div className="panel-heading" role="tab" id="headingNine">
																					  <h4 className="panel-title">
																						<a className="collapsed" role="button" data-toggle="collapse" data-parent="#bzCollapse" href="#collapseOpenNine" aria-expanded="false" aria-controls="collapseNine">
																							Adaptability to new discoveries
																						</a>
																					  </h4>
																					</div>
																					<div id="collapseOpenNine" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingNine">
																					  <div className="panel-body">
																						<p>The students with an ability to adapt and learn with new discoveries in the homeopathic sciences also have an opportunity to get very good salary packages.</p>
																					  </div>
																					</div>
																				</div>

																			  </div>
																			</div>
																			<p>The salaries are also decent for BHMS doctors according to their positions. The salary ranges from 5 lakhs to 10 lakhs per annum for skilled homeopathic practitioners. The fresh BHMS students will be earning around 4 lakhs to 6 lakhs per annum. The experienced BHMS doctors will be earning around 6 lakhs to 10 lakhs or even more. The salaries depend upon the location and recruiters.</p>
																			<p>The increased demand for homeopathic professionals has resulted in availability of ample job opportunities in the field of homeopathy for the skilled BHMS students.</p>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
                    <div className="vc_row-full-width vc_clearfix"></div>
                      <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684117259 tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom">
											<div className="vc_row container" id="scholarships">
												<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
													<div className="vc_column-inner tm-responsive-custom-12751771">
														<div className="wpb_wrapper">
															<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
																<section className="tm-vc_cta3-container tm-sepcolor-default">
																	<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
																		<div className="tm-vc_cta3_content-container">
																			<div className="tm-vc_cta3-content">
																				<header className="tm-vc_cta3-content-header tm-wrap">
																					<div className="tm-vc_cta3-headers tm-wrap-cell">
																						<h5 className="tm-custom-heading">Scholarships for BHMS</h5> </div>
																					<div className="heading-seperator"><span></span></div>
																				</header>
																			</div>
																		</div>
																	</div>
																</section>
															</div>
															<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
																<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0" style={{marginBottom:"30px"}}>
																	<div className="vc_column-inner  ">
																		<div className="wpb_wrapper">
																			<h6>Types of Scholarships</h6>
																			<p>The BHMS scholarships always help the students to ease their financial burden who want to pursue a BHMS course from a prestigious institute or a university. These BHMS scholarships are available for Indian students who wish to pursue their dream of studying in top BHMS colleges and universities in India and abroad.</p>
																			<p><b>Different types of BHMS scholarships and financial aids are offered to Indian students who wish to pursue BHMS courses in recognised colleges and universities.</b></p>
																			<ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
																				<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Merit-based Scholarships - </b>This type of scholarship is based on excellent academic record of the students</span></li>
																				<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Merit-cum-Aptitude-based Scholarships - </b>This type of scholarship is based on excellent academic record and individual aptitude</span></li>
																				<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Need-based Scholarships and Fellowships – </b>This type of scholarships is usually awarded for students from low-income families</span></li>
																				<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Gender-based Scholarships – </b>This type of scholarship is usually given to female students</span></li>
																				<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>External Scholarships – </b>This type of scholarships is sponsored by renowned companies and organizations for deserving candidates</span></li>
																				<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>International Scholarships – </b>This type of scholarships are provided by international universities and institutes for deserving students</span></li>
																				<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>National Scholarships – </b>The national scholarships are provided by national universities and institutes for all the deserving students</span></li>
																				<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>State Scholarships – </b>The state scholarships are provided by the state government for the deserving students</span></li>
																				<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Private Scholarships – </b>The private scholarships are provided by private universities and institutes for all the deserving candidates</span></li>
																				<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Fee Waivers - </b>This type of scholarship is given to a usually specific percentage, where 5% to 100% of the total fees is waived off</span></li>
																				<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Teaching Assistantship – </b>This is provided through part-time teaching jobs in the university</span></li>
																				<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Summer Fellowships – </b>Summer internship opportunities are given to the students for obtaining practical exposure and income</span></li>
																			</ul>
																			
																		</div>
																	</div>
																</div>
																<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-8 tm-zindex-0">
																	<div className="vc_column-inner  ">
																		<div className="wpb_wrapper">
																			<div className="bz-collapse1">
																				<div className="panel-group" id="scholarshipsCollapse" role="tablist" aria-multiselectable="true">
																					<div className="panel panel-default">
																					  <div className="panel-heading" role="tab" id="headingOneScholar">
																						<h4 className="panel-title">
																						  <a role="button" data-toggle="collapse" data-parent="#scholarshipsCollapse" href="#collapseOpenOneScholar" aria-expanded="true" aria-controls="collapseOpenOneScholar">
																							HOW TO AVAIL SCHOLARSHIPS?
																						  </a>
																						</h4>
																					  </div>
																					  <div id="collapseOpenOneScholar" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOneScholar">
																						<div className="panel-body">
																						  <p>It is important to begin research about scholarship application dates and deadlines at the earliest.</p>
																						  <p><b>Steps to avail a scholarship are:</b></p>
																						  <ol className="numbers-list">
																							  <li>There are two main categories of scholarships – Government and Private Scholarships. It is very important to choose which type of scholarship you want to avail before proceeding with applications.</li>
																							  <li>Research and monitor regularly about the announcement of scholarship exams notifications and details, application deadlines and eligibility.</li>
																							  <li>Find out which Scholarships Exam eligibility criteria match your academic qualifications.</li>
																							  <li>Fill the Application Form without missing the last date.</li>
																							  <li>Submit the requested important documents like previous academic transcripts, age and address proof, certificates of participation in sports and extra-curricular activities, proof of parent’s income (if applicable), statement of purpose, etc.</li>
																							  <li>Prepare well for competitive scholarship examinations and answer each question very carefully.</li>
																							  <li>Attend mock tests if possible.</li>
																							  <li>Some universities and institutes also conduct an additional Personal Interview round after the examination so it is good to be prepared.</li>
																							  <li>If you are applying to a university or college abroad, check for visa requirements.</li>
																						  </ol>
																						</div>
																					  </div>
																					</div>
																					<div className="panel panel-default">
																					  <div className="panel-heading" role="tab" id="headingTwoScholar">
																						<h4 className="panel-title">
																						  <a className="collapsed" role="button" data-toggle="collapse" data-parent="#scholarshipsCollapse" href="#collapseOpenTwoScholar" aria-expanded="false" aria-controls="collapseTwoScholar">
																							HOW TO PREPARE FOR SCHOLARSHIP EXAMINATIONS?
																						  </a>
																						</h4>
																					  </div>
																					  <div id="collapseOpenTwoScholar" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwoScholar">
																						<div className="panel-body">
																						  <p>The process of securing a scholarship is very competitive because there are many applicants but limited scholarship offers. To get a scholarship the candidate should stand out from others. This can be achieved by:</p>
																						  <ul className="tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591385700">
																							<li><i className="bz-skyblue fa fa-circle"></i> <span className="tm-list-li-content">Early organization, strategy, planning, time management and preparation</span></li>
																							<li><i className="bz-skyblue fa fa-circle"></i> <span className="tm-list-li-content">Review the mode, duration, syllabus, marking scheme and examination pattern of the entrance exams</span></li>
																							<li><i className="bz-skyblue fa fa-circle"></i> <span className="tm-list-li-content">Stay up-to-date with the latest news and current affairs of national and international significance</span></li>
																							<li><i className="bz-skyblue fa fa-circle"></i> <span className="tm-list-li-content">Brush up on General Knowledge topics</span></li>
																							<li><i className="bz-skyblue fa fa-circle"></i> <span className="tm-list-li-content">Prepare for Logical Reasoning questions with continuous practice</span></li>
																							<li><i className="bz-skyblue fa fa-circle"></i> <span className="tm-list-li-content">Build Vocabulary, Comprehension and Grammar</span></li>
																							<li><i className="bz-skyblue fa fa-circle"></i> <span className="tm-list-li-content">Practice basic Mathematics and memorize formulae</span></li>
																							<li><i className="bz-skyblue fa fa-circle"></i> <span className="tm-list-li-content">Hard work and determination are essential during preparation</span></li>
																							<li><i className="bz-skyblue fa fa-circle"></i> <span className="tm-list-li-content">Avoid distractions like TV, music and video games</span></li>
																							<li><i className="bz-skyblue fa fa-circle"></i> <span className="tm-list-li-content">To get more details and assistance about BHMS courses the students can contact us</span></li>
																							<li><i className="bz-skyblue fa fa-circle"></i> <span className="tm-list-li-content">Prepare for the four main categories of questions usually asked in competitive examinations – Mathematical Skills, Intelligence & Critical/Logical Reasoning, Verbal Ability & Language Comprehension and General Awareness.</span></li>
																						  </ul>
																						</div>
																					  </div>
																					</div>
																				  </div>
																				</div>
																		</div>
																	</div>
																</div>
																<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-4 tm-zindex-0">
																	<div className="vc_column-inner  ">
																		<div className="wpb_wrapper scholarship-img">
																			<img src="/assets/img/scholarship.jpg" className="img-responsive lozad" alt="Scholarships"/>
																			
																		</div>
																	</div>
																</div>

															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="vc_row container">
												<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
													<div className="vc_column-inner tm-responsive-custom-12751771">
														<div className="wpb_wrapper">
															<hr/>
															<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
																<section className="tm-vc_cta3-container tm-sepcolor-default">
																	<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
																		<div className="tm-vc_cta3_content-container">
																			<div className="tm-vc_cta3-content">
																				<header className="tm-vc_cta3-content-header tm-wrap">
																					<div className="tm-vc_cta3-headers tm-wrap-cell">
																						<h5 className="tm-custom-heading">Top BHMS Recruitment Agencies in Bangalore</h5> </div>
																					<div className="heading-seperator"><span></span></div>
																				</header>
																			</div>
																		</div>
																	</div>
																</section>
															</div>
															<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
																<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
																	<div className="vc_column-inner">
																		<div className="wpb_wrapper">
																			<p><b>Some of the top recruiters hiring BHMS students are:</b></p>
																			<div className="row text-center brand-logos">
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive lozad" src="/assets/img/brands/positive-homeopathy.jpg" alt="Positive Homeopathy"/></div>
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive lozad" src="/assets/img/brands/dr-reddys.jpg" alt="Dr Reddys Laboratories"/></div>
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive lozad" src="/assets/img/brands/homeolife.jpg" alt="Homeolife"/></div>
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive lozad" src="/assets/img/brands/dr-batras-homeopathy.jpg" alt="Dr Batras Homeopathy"/></div>
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive lozad" src="/assets/img/brands/wellness-homeopathy.jpg" alt="Wellness Homeopathy"/></div>
																				<div className="col-sm-2 col-xs-4"><img className="img-responsive lozad" src="/assets/img/brands/sanjivani.jpg" alt="Sanjeevani Homeo Hospitals"/></div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
                  <div className="vc_row-full-width vc_clearfix"></div>
                    <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567685146842 tm-zindex-0 tm-bgcolor-skincolor tm-bgimage-position-center_center" style={{backgroundColor:"#94af1d"}}>
											<div className="vc_row container">
												<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0">
													<div className="vc_column-inner  ">
														<div className="wpb_wrapper">
															<div className="wpb_raw_code wpb_content_element wpb_raw_html vc_custom_1568379925491 tm-textcolor-white">
																<div className="wpb_wrapper">
																	<h5 style={{textAlign:"center",fontSize:"29px",lineHeight:"33px",fontWeight:"400"}} className="tm-custom-heading"><i className="fa fa-graduation-cap" aria-hidden="true"></i> Get Direct BHMS Admission Now, <u><Link to="#enquiry" data-toggle="modal">Click here</Link></u></h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> 
                  </div>
                    <div className="vc_row-full-width vc_clearfix"></div>
                  </div>
                  </article>
                </main>
              </div>
            </div>
          </div>
        </div>
        );
    }
}

// export default Home;