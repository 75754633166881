import React from "react";
import {Link} from "react-router-dom";
import axios from 'axios'
import { useState, useEffect, Component } from 'react';
import Helmet from 'react-helmet';

export default class Colleges extends Component {
    
    constructor(){
        super();
        this.state={
          colleges:[],
          isLoading:false
        }
      }
      async componentDidMount() {
        this.getList();
      }
      //API CALLS
      getList=()=>{
        this.getUser();
      }

      async getUser(){
        this.setState({isLoading:true})
        let usersData=await axios.get('/colleges-page-data')
                    .then(res=>{
                        this.setState({
                            colleges:res.data.colleges
                        },()=>{
                        })
                        this.setState({isLoading:false})
                    })
                    .catch(err=>{
                        this.setState({isLoading:false})
                        console.log(err);
                    })
      }
render(){
        const {colleges, isLoading}=this.state;        
        return (
          <div id="content-wrapper" className="site-content-wrapper">
            <Helmet>
                <title>Entrance Exams</title>
            </Helmet>
          <div id="content" className="site-content container">
             <div id="content-inner" className="site-content-inner row multi-columns-row">
                <div id="primary" className="content-area col-md-8 col-lg-8 col-xs-12 sub">
                   <main id="main" className="site-main">
                          <div className="row">
                              <div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
                                  <div className="vc_column-inner tm-responsive-custom-12751771">
                                      <div className="wpb_wrapper">
                                                  <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                                      <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                          <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                              <div className="tm-vc_cta3_content-container">
                                                                  <div className="tm-vc_cta3-content">
                                                                      <header className="tm-vc_cta3-content-header tm-wrap">
                                                                          <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                                              <h3 className="tm-custom-heading">BHMS <span>UG-NEET</span></h3> </div>
                                                                          <div className="heading-seperator"><span></span></div>
                                                                      </header>
                                                                      <div className="tm-cta3-content-wrapper">
                                                                          <p>The National Eligibility cum Entrance Test NEET exam is a sole entrance exam that is conducted for admissions to all the medical, dental and Ayush related courses for students across India. The NEET exam is solely conducted by National Testing Agency (NTA), the sole governing body under the Ministry of Human Resource Development, Government of India. The NTA was formed under Section 14 of National Medical Commission Act,2019 by the Government of India. This act empowers the NTA to conduct NEET Exam for admissions to all the undergraduate courses of Medical, Dental and Ayush seats.</p>
                                                                          <p>The NEET exam replaces the All-India Pre-Medical Test (AIPMT) entrance exam that was previously conducted for admissions to MBBS, BDS and AYUSH courses in the year 2019. The students who are interested to get admissions and study the above courses are required to qualify the NEET exam and attend counselling sessions for the allotment seats.</p>
                                                                          <p>The students who are interested to join BHMS course are required to qualify the NEET exam and also attend the counselling sessions conducted by MCC for All India Quota (AIQ) Seats and KEA counselling for allotment of seats in case of Ayush colleges in Karnataka. The students have to attend the different counselling sessions conducted by different states for allotment of BHMS seats in their respective states.</p>
                                                                          <table className="table table-hover table-striped exam-table">
                                                                              <tbody>
                                                                              <tr>
                                                                              <th>Level</th>
                                                                              <td>All India Level</td>
                                                                              </tr>
                                                                              <tr>
                                                                              <th>Frequency</th>
                                                                              <td>Once in a year</td>
                                                                              </tr>
                                                                              <tr>
                                                                              <th>Mode</th>
                                                                              <td>Pen & paper (Offline)</td>
                                                                              </tr>
                                                                              <tr>
                                                                              <th>Language</th>
                                                                              <td>English, Bengali, Hindi, Tamil, Telugu, Oriya, Marathi, Assami, Urdu, Kannada</td>
                                                                              </tr>
                                                                              <tr>
                                                                              <th>Course</th>
                                                                              <td>MBBS, BDS, BHMS, BUMS</td>
                                                                              </tr>
                                                                              <tr>
                                                                              <th>Significance</th>
                                                                              <td>The exam is required for the students to join professional courses related to Medical, Ayush</td>
                                                                              </tr>
                                                                              <tr>
                                                                              <th>Examination Fee</th>
                                                                              <td>General (UR)- Rs 1500/- <br/>General- EWS/ OBC (NCL)- Rs 1400/- <br/>SC/ ST/ PwD/ Transgender- Rs 800/-</td>
                                                                              </tr>
                                                                              <tr>
                                                                              <th>Centre</th>
                                                                              <td>36 state across India</td>
                                                                              </tr>
                                                                              <tr>
                                                                              <th>Duration</th>
                                                                              <td>3 hours</td>
                                                                              </tr>
                                                                              <tr>
                                                                              <th>Format</th>
                                                                              <td>180 Questions</td>
                                                                              </tr>
                                                                              <tr>
                                                                              <th>Marking Scheme</th>
                                                                              <td>Negative marking for wrong answers</td>
                                                                              </tr>
                                                                              <tr>
                                                                              <th>Who can apply?</th>
                                                                              <td>The students who have completed their PUC or 10+2 with Physics/ Chemistry/ Biology(PCB) combinations with 50% aggregate can apply for this exam</td>
                                                                              </tr>
                                                                              <tr>
                                                                              <th>Age Limit</th>
                                                                              <td>Candidates should be 17 years as of December 31 of the admission year</td>
                                                                              </tr>
                                                                              <tr>
                                                                              <th>Subjects / Syllabus</th>
                                                                              <td>Subjects of undergraduate level</td>
                                                                              </tr>
                                                                              <tr>
                                                                              <th>Cut-off</th>
                                                                              <td>Percentile ≥ 90 to get admission into a reputed college. Other colleges accept percentile within 50-85 range</td>
                                                                              </tr>
                                                                              </tbody>
                                                                          </table>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </section>
                                                  </div>
                                          
                                      </div>
                                  </div>
                              </div>
                          </div>

                   </main>
                </div>
                <aside id="sidebar-right" className="widget-area col-md-4 col-lg-4 col-xs-12 sidebar">
                <aside id="themetechmount-recent-posts-5" className="widget-odd widget-9 widget themetechmount_widget_recent_entries">
                      <h3 className="widget-title">BHMS Colleges in Bangalore</h3>
                      <ul className="p-10 similar-crs tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
                      {colleges && colleges.length > 0 && colleges.map((college, index) => {
                        return (
                          <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><Link to={`/colleges/${college.slug}`}>{college.college}</Link></span></li>
                          );
                        })}
                      </ul>
                   </aside>
                   <aside id="acadevo_contact_widget-4" className="widget-even widget-14 widget acadevo_contact_widget">
                      <Link className="hover" to="/syllabus"><h3 className="widget-title">BHMS Syllabus <i className="fa fa-arrow-circle-right" aria-hidden="true"></i></h3></Link>
                   </aside>
                  
                   <aside id="acadevo_contact_widget-4" className="widget-even widget-14 widget acadevo_contact_widget">
						<a className="hover" href="tel:+918095797575"><h3 className="widget-title"><i className="fa fa-phone" aria-hidden="true"></i> Helpline: +918095797575</h3></a>
					</aside>
                </aside>
               
             </div>
             
          </div>
         
       </div>
        )
    };

}

// export default Home;