import React from "react";
import {Link} from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useState, useEffect, Component } from 'react';
import Helmet from 'react-helmet';
import Select from 'react-select'
import countryFile from './Country';
import checkNumber from './CheckMobileNumber';

export default class Syllabus extends Component {
    
    constructor(props){
        super(props);
        this.state={
          blogs:[],
          notifications:[],
          reviews:[],
		  countries: [], 
		  states: [],
		  stateValue:'',
		  mobile:''
        }
      }

	  selectCountry = (val) => {
		this.setState({ stateValue: '' });
		const state_arr = countryFile.s_a[val.key].split("|"); 
		const stateArray = [];
		stateArray.push({
			value: '', 
			label: 'Select'
		});
		for (var i = 0; i < state_arr.length; i++) {
			stateArray.push({
			value: state_arr[i], 
			label: state_arr[i]
			});
		}
		this.setState({ states: stateArray });
		var Country = val.value;
        let check = checkNumber.checkNumber(Country,this.state.mobile);
        if(check === 2){
          this.setState({mobile:''});
        }
	  }
	
	  selectRegion = (val) => {
		if(val.value !== '' && val.value !== null){
			this.setState({stateValue:val});
		} else {
			this.setState({stateValue:''});
		}
	  }

	  updateMobile = (value) => {
		this.setState({mobile:value});
	  }
     
      componentDidMount() {
		const countryArray = [];
        var country1 = countryFile.country_arr;
        countryArray.push({
          value: '', 
          label: 'Select',
          key:[0]
        });
        for (var i = 0; i < country1.length; i++) {
          countryArray.push({
            value: country1[i], 
            label: country1[i],
            key:[i+1]
          });
        }
        this.setState({countries:countryArray});
		this.getUser();
      }
      //API CALLS
      getList=()=>{
        this.getUser();
      }

      async getUser(){
        let usersData=await axios.get('/syllabus-page-data')
                        .then(res=>{
                        //   return res.data;
                          this.setState({
                            blogs:res.data.blogs,
                            notifications:res.data.notifications,
                            reviews:res.data.collegerates
                          },()=>{
                            // console.log(this.state)
                          })
                        })
                        .catch(err=>{
                          console.log(err);
                        })
      }

      handleSubmit(event) {
        // console.log(event.target.country.value);
        event.preventDefault();
        jQuery("#overlay").fadeIn(300);　
        const formData = new FormData(event.target);
        console.log(formData);
        axios.post('/store-enquiry', formData)
        .then(({data})=>{
            event.target.reset()
            if(data.status === 200){
                Swal.fire({
                    icon:"success",
                    text:data.message
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else if(data.status === 201){
                Swal.fire({
                    text:'We have already received an enquiry from this mobile number or email.',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else {
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            }
        })
      }

	  notificationDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return month+ ' ' +date+', ' +year;
      }

	  blogDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return month+ ' ' +date+', ' +year;
      }

    render(){
        const {blogs}=this.state;
        const {notifications}=this.state;
        const {reviews}=this.state;
		const { countries, states, stateValue, mobile } = this.state;
        return (
          <div id="content-wrapper" className="site-content-wrapper">
            <Helmet>
                <title>BHMS Syllabus</title>
            </Helmet>
              <div id="content" className="site-content container">
                    <div id="content-inner" className="site-content-inner row multi-columns-row">
					  <div id="primary" className="content-area col-md-8 col-lg-8 col-xs-12 sub">
						 <main id="main" className="site-main">
								<div className="row">
									<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
										<div className="vc_column-inner tm-responsive-custom-12751771">
											<div className="wpb_wrapper">
											<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
															<section className="tm-vc_cta3-container tm-sepcolor-default">
																<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
																	<div className="tm-vc_cta3_content-container">
																		<div className="tm-vc_cta3-content">
																			<header className="tm-vc_cta3-content-header tm-wrap">
																				<div className="tm-vc_cta3-headers tm-wrap-cell">
																					<h3 className="tm-custom-heading">Course Curriculum for Bachelor of Homeopathic Medicine and Surgery (BHMS)</h3> </div>
																				<div className="heading-seperator"><span></span></div>
																			</header>
																			<div className="tm-cta3-content-wrapper">
																				<p>The BHMS students will be studying the following subjects during their course duration:</p>
																				<table className="table table-hover table-striped exam-table">
																					<thead>
																						<tr>
																						<th>Sl. No.</th>
																						<th>Subjects of Study</th>
																						</tr>
																					</thead>
																					<tbody>
																					<tr>
																						<td>1</td>
																						<td>Organon of Medicine, Principles of Homoeopathic Philosophy and Psychology</td>
																					</tr>
																					<tr>
																						<td>2</td>
																						<td>Anatomy, Histology and Embryology</td>
																					</tr>
																					<tr>
																						<td>3</td>
																						<td>Physiology including Biochemistry</td>
																					</tr>
																					<tr>
																						<td>4</td>
																						<td>Homoeopathic Pharmacy</td>
																					</tr>
																					<tr>
																						<td>5</td>
																						<td>Homoeopathic Materia Medical</td>
																					</tr>
																					<tr>
																						<td>6</td>
																						<td>Pathology and Microbiology including Parasitology Bacteriology and Virology</td>
																					</tr>
																					<tr>
																						<td>7</td>
																						<td>Forensic Medicine & Toxicology</td>
																					</tr>
																					<tr>
																						<td>8</td>
																						<td>Organon of Medicine and Principles of Homoeopathic Philosophy</td>
																					</tr>
																					<tr>
																						<td>9</td>
																						<td>Homoeopathic Materia Medical</td>
																					</tr>
																					<tr>
																						<td>10</td>
																						<td>Surgery including ENT, Eye Dental and Homeo therapeutics</td>
																					</tr>
																					<tr>
																						<td>11</td>
																						<td>Obstetrics & Gynaecology Infant care and Homeo therapeutics</td>
																					</tr>
																					<tr>
																						<td>12</td>
																						<td>Practice of Medicine and Homeo. Therapeutics</td>
																					</tr>
																					<tr>
																						<td>13</td>
																						<td>Practice of Medicine & Homeo therapeutics</td>
																					</tr>
																					<tr>
																						<td>14</td>
																						<td>Surgery including ENT, Ophthalmology & dental & Homeo. therapeutics</td>
																					</tr>
																					<tr>
																						<td>15</td>
																						<td>Obstetrics & Gynaecology Infant care & Homeo. therapeutics</td>
																					</tr>
																					<tr>
																						<td>16</td>
																						<td>Homoeopathic Materia Medical</td>
																					</tr>
																					<tr>
																						<td>17</td>
																						<td>Organon of Medicine</td>
																					</tr>
																					<tr>
																						<td>18</td>
																						<td>Practice of Medicine & Homeo therapeutics</td>
																					</tr>
																					<tr>
																						<td>19</td>
																						<td>Homoeopathic Materia Medical</td>
																					</tr>
																					<tr>
																						<td>20</td>
																						<td>Organon of Medicine</td>
																					</tr>
																					<tr>
																						<td>21</td>
																						<td>Repertory</td>
																					</tr>
																					<tr>
																						<td>22</td>
																						<td>Community Medicine</td>
																					</tr>
																					
																					</tbody>
																				</table>
																			</div>
																			<hr/>
																			<header className="tm-vc_cta3-content-header tm-wrap">
																				<div className="tm-vc_cta3-headers tm-wrap-cell">
																					<h5 className="tm-custom-heading">Admission Process at BHMS colleges in Bangalore</h5> </div>
																				<div className="heading-seperator"><span></span></div>
																			</header>
																			<div className="tm-cta3-content-wrapper">
																				<p><b>The interested students can join the BHMS course by two modes of admissions. They are:</b></p>
																				<ol>
																					<li>Merit Admissions</li>
																					<li>Direct Admissions</li>
																				</ol>
																				<h6>1. Merit Admissions</h6>
																				<p>The first mode of admission is called a merit admission procedure. The students who are interested to join the BHMS degree should mandatorily appear for the NEET entrance exams and undergo counselling to choose their desired colleges for merit seats. The merit seats are allotted to the students according to their rankings and marks obtained by them in their PUC or 10+2 classes</p>
																				<p>There are two types of counselling for the Bachelor of Homeopathic Medicine and Surgery (BHMS) course.</p>
																				<ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
																					<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Karnataka Examination Authority (KEA) Counselling</b></span></li>
																					<p>The students who are interested to join the government homeopathic colleges and other homeopathic colleges affiliated to the government should undergo the counselling procedures of Karnataka Examination Authority (KEA).</p>
																					<p>The students who intend to join for the merit seats under government quota are required to complete the counselling procedures and join for BHMS course in their chosen colleges. The Homeopathic colleges under the Karnataka Examination Authority (KEA) are affiliated to the Rajiv Gandhi University of Health Sciences (RGUHS). The fees are fixed by the government for merit admissions.</p>
																					<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Medical Counselling Committee (MCC) Counselling</b></span></li>
																					<p>The students who are interested to join the private/deemed Homeopathic colleges and universities that are affiliated to colleges and universities recognised by Central Council of Indian Medicine, Ministry of AYUSH, Government of India. The students are required to apply for the MCC counselling procedures for getting admissions to these colleges. The students should qualify the MCC counselling procedures to get admission to their desired private homeopathic educational institutions for Bachelor of Homeopathic Medicine and Surgery (BHMS) programme.</p>
																				</ul>
																				<h6>2. Direct Admissions</h6>
																				<p>The eligible students who have appeared for NEET-UG exam can also get admission to BHMS programme through direct admissions. The students under direct admissions can choose their desired colleges for their study. The students interested to get direct admission can contact us for more details about direct admissions. The fees are slightly higher for direct admissions.</p>
																			</div>
																		</div>
																	</div>
																</div>
															</section>
														</div>
											</div>
										</div>
									</div>
								</div>

						 </main>
					  </div>
					  <aside id="sidebar-right" className="widget-area col-md-4 col-lg-4 col-xs-12 sidebar">
						 <aside id="themetechmount-recent-posts-5" className="widget-odd widget-9 widget themetechmount_widget_recent_entries">
							<h3 className="widget-title">Notifications</h3>
							<ul className="p-10 tm-recent-post-list">
								{notifications && notifications.length > 0 && notifications.map((notification, index) => {
                        			return (
										<li className="tm-recent-post-list-li" key={`n${notification.id}`}>
											<Link to={`/notifications/${notification.slug}`}>
												<img width="150" height="150" src={`/storage/notifications/${notification.image2}`} alt=""/>
											</Link>
											<Link to={`/notifications/${notification.slug}`}>{notification.title}</Link>
											<span className="post-date">{this.blogDate(new Date(notification.updated_at))}</span>
										</li>
								    );
							    })}
							</ul>
						 </aside>
						 <aside id="themetechmount-recent-posts-5" className="widget-odd widget-9 widget themetechmount_widget_recent_entries">
							<h3 className="widget-title" style={{backgroundColor: '#94af1d'}}>Blogs</h3>
							<ul className="p-10 tm-recent-post-list">
								{blogs && blogs.length > 0 && blogs.map((blog, index) => {
									return (
									<li className="tm-recent-post-list-li" key={`bl${blog.id}`}>
										<Link to={`/blogs/${blog.slug}`}><img width="150" height="150" src={`/storage/blogs/${blog.image2}`} className="attachment-thumbnail size-thumbnail wp-post-image" alt={blog.title}/></Link>
										<Link to={`/blogs/${blog.slug}`}>{blog.title}</Link>
										<span className="post-date">{this.notificationDate(new Date(blog.updated_at))}</span>
									</li>
									)
								})}
							</ul>
						 </aside>
						 <aside id="categories-5" className="rating widget-even widget-10 widget widget_categories">
							<h3 className="widget-title" style={{backgroundColor: '#94af1d'}}>Reviews & Ratings of BHMS Colleges in Bangalore</h3>
							<div className="rate-box">
							{reviews && reviews.length > 0 && reviews.map((review, index) => {
								const oneStar = (review.rating >= 1) ?  'fa fa-star' :  'fa fa-star-o' ;
								const twoStar = (review.rating >= 2) ?  'fa fa-star' :  'fa fa-star-o' ;
								const threeStar = (review.rating >= 3) ?  'fa fa-star' :  'fa fa-star-o' ;
								const fourStar = (review.rating >= 4) ?  'fa fa-star' :  'fa fa-star-o' ;
								const fiveSar = (review.rating >= 5) ?  'fa fa-star' :  'fa fa-star-o' ;
								return (
									<div className="recent-box text-left" key={`n${review.id}`}>
										<div className="recent">
										<h6><span><div className="rating-stars">
											<ul id="">
												<li className="star rate-this" title="1 star" data-value="1" style={{margin: 0}}>
												<i className={oneStar}></i>
												</li>
												<li className="star rate-this" title="2 stars" data-value="2" style={{margin: 0}}>
												<i className={twoStar}></i>
												</li>
												<li className="star rate-this" title="3 stars" data-value="3" style={{margin: 0}}>
												<i className={threeStar}></i>
												</li>
												<li className="star rate-this " title="4 stars" data-value="4" style={{margin: 0}}>
												<i className={fourStar}></i>
												</li>
												<li className="star rate-this " title="5 stars" data-value="5" style={{margin: 0}}>
												<i className={fiveSar}></i>
												</li>
											</ul> 
										</div></span> <span>|</span> {review.name} <span>|</span> 
											<Link to={`/colleges/${review.url}`}>{review.college}</Link> <span>|</span> <span>{review.date}</span></h6>
										<p>{review.review}</p>
										</div>
									</div>
								);
                    		})}
							</div>
						 </aside>
						 <aside id="themetechmount-recent-posts-5" className="widget-odd widget-9 widget themetechmount_widget_recent_entries">
							<h3 className="widget-title">Apply now</h3>
							<div className="widget-content p-10 side-form">
								<form onSubmit={this.handleSubmit} className="course-form clearfix">
									<div className="row">
										<div className="col-xs-12">
											<div className="text-block">
												<input type="text" name="name" placeholder="Name *" required/> </div>
										</div>
										<div className="col-xs-12">
											<div className="text-block">
												<input type="email" name="email" placeholder="Email *" required/> </div>
										</div>
										<div className="col-xs-12">
											<div className="text-block">
												<input type="text" value={mobile} onChange={e => this.updateMobile(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} className="EnquiryMobile" name="phone" placeholder="Phone *" required/> </div>
										</div>
										<div className="col-xs-12">
											<div className="text-block">
												<input type="text" name="course" placeholder="Course *" required/>
												<input type="hidden" name="source" value="2"/> </div>
										</div>
										<div className="col-xs-12 pad-5">
											<div className="text-block">
                          						<Select options={countries} name="country"  placeholder="Select Country" required onChange={this.selectCountry} />
											</div>
										</div>
                    					<div className="col-xs-12 pad-5">
											<div className="text-block">
                          						<Select options={states} value={stateValue} name="state" className="select-state" placeholder="Select State" required onChange={this.selectRegion} />
											</div>
										</div>
										<div className="col-xs-12 text-center">
											<input type="submit" value="Submit" className="btn submit"/> </div>
									</div>
								</form>
							</div>
						 </aside>
						 
						 <aside id="acadevo_contact_widget-4" className="widget-even widget-14 widget acadevo_contact_widget">
							<a className="hover" href="tel:+918095797575"><h3 className="widget-title"><i className="fa fa-phone" aria-hidden="true"></i> Helpline: +918095797575</h3></a>
						 </aside>
					  </aside>
				   </div>
              </div>
        </div>
        );
    }
}

// export default Home;